import React, { useState } from 'react';
import { Box, Container, Alert, Backdrop, CircularProgress, Grid } from '@mui/material';
import { SignUpForm, VerifyOTPRegistraionForm } from '../../components/organisms';
import { AuthRepository } from '../../repository';
import _ from "lodash";
import { useNavigate } from 'react-router-dom';

const STATUS = {
  SIGN_UP: 1,
  VERIFY: 2,
}

export default function SignUpPage() {
  const [screen, setScreen] = useState(STATUS.SIGN_UP);
  const [value, setValue] = useState(null);

  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState('');
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  const onUpdate = (data) => {
    setScreen(STATUS.VERIFY);
    setValue(data);
  }

  const onLogin = async () => {
    setResError(false);
    const mobile = localStorage.getItem('phone');
    const password = value?.password;
    const response = await AuthRepository.onLogin({ user_name: mobile, password });
    handleOpen()
    if (response) {
      if (response?.status_code == 1) {
        localStorage.setItem('token', _.get(response, 'data.token', ''));
        localStorage.setItem('user', JSON.stringify(_.get(response, 'data.user', '')));
        localStorage.removeItem('phone')
        navigate("/my-suite");
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
      }
      handleClose()
    }

  };


  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }} >
      {isResError && <Grid item>
        <Alert severity="error">{errorResMessage}.</Alert>
      </Grid>}
      {STATUS.SIGN_UP == screen ? <SignUpForm onPassResPonse={(data) => {
        onUpdate(data)
      }} /> :

        <Box pt={5} pb={5}>

          <VerifyOTPRegistraionForm onResponse={onLogin} viewChange={() => setScreen(STATUS.SIGN_UP)}
          />
        </Box>}


      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}
