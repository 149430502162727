import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Grid,
  Typography,
  useTheme,
  Alert,
  CircularProgress,
  Backdrop,
  CardContent,
  Divider,
  Box,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@mui/material";
import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
  TableComponent
} from "../../components/atoms";
import { CommonRepositoty } from "../../repository";
import _ from "lodash";
import { getText, onGetCurrencySymble } from "../../utils/Credentials";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function CalculatorPage() {
  const [list, setList] = useState([]);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");

  const [location, setLocation] = useState(0);
  const [isLocationValid, setIsLocationValid] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const [packageFrom, setPackageFrom] = useState("");
  const [weight, setWeight] = useState(0);
  const [isWeightValid, setIsWeightValid] = useState(false);
  const [weightError, setWeightError] = useState(false);
  const [weightVal, setWeightVal] = useState(0);
  const [genaratePrice, setGenaratePrice] = useState(false);
  const [resposeData, setResposeData] = useState(null);

  const column = [
    {
      name: "weight",
      label: "Weight",
      options: {
       filter: true,
       sort: true,
       setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
       customBodyRender: (value, tableMeta, updateValue) => {
        
        return (
          <Typography
          variant="subtitle2"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          color={'black'}
        >
          {`  ${value}${_.get(resposeData, 'unit', 'kg')}`}
        </Typography>
        );
      },
      }
     },
     {
      name: "amount",
      label: "Shipping Cost",
      options: {
       filter: true,
       sort: true,
       setCellProps: () => ({
        style: {
          textAlign: "center",
        },
      }),
       customBodyRender: (value, tableMeta, updateValue) => {
        
        return (
          <Typography
          variant="subtitle2"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          color={'black'}
        >
          {`${value} ${onGetCurrencySymble(_.get(resposeData, 'currency', 'USD'))} `}
        </Typography>
        );
      },
      }
     },
     {
      name: "flag",
      label: "Shipping Carrier",
      options: {
       filter: true,
       sort: true,
       setCellProps: () => ({
        style: {
          textAlign: "center",
        },
      }),
       customBodyRender: (value, tableMeta, updateValue) => {
      
        return (
          <img src={value} />
        );
      },
      }
     },
    
    ];
  const [data, setData] = useState([]);

  const [open, setOpen] = React.useState(false);

  

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    onCallLocations();
  }, []);

  const onCallLocations = async () => {
    handleOpen();
    const data = await CommonRepositoty.onGetLocationList();
    if (data?.status_code == 1) {
      setError(false);
      setList(
        _.get(data, "data", []).map((item) => {
          return { ...item, name: item?.short_name };
        })
      );
    } else {
      setError(true);
    }
    if (data) {
      handleClose();
    }
    setErrorMessage(_.get(data, "message", ""));
  };

  const onGetUpdate = async () => {
    setResError(false);
    if (isLocationValid && isWeightValid) {
      handleOpen();
      const data = {
        packageFrom,
        unit: "kg",
        weight,
      };
      const response = await CommonRepositoty.onGetShippingCost({
        packageFrom,
        unit: "kg",
        weight,
      });

      if (response) {
        setGenaratePrice(_.get(response, "status", false));
        if (response?.status_code == 1) {
          const value = _.get(response, 'data.rates[0]')
          setResposeData(value);
          const flag = _.get(value,'courier_logo','');
          const list  = _.map(_.get(value, 'rates',[]),(item,index)=>{

            return {...item, flag}
          })
          setData(list)
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, "message", ""));
        }
        handleClose();
      }
    } else {
      if (!isLocationValid) {
        setLocationError(true);
      }
      if (!isWeightValid) {
        setWeightError(true);
      }
    }
  };

  const onWeight = (e) => {
    const text = getText(e);
    const number = parseFloat(text).toFixed(3);
    setWeightVal(number);
    setIsWeightValid(number > 0);
    setWeightError(false);
    setWeight(text);
  };

  const onChangeLocation = (e) => {
    const text = getText(e);
    setLocation(text);
    setLocationError(false);
    setIsLocationValid(text > 0);
    const value = _.find(list, (item) => item.id == text);
    setPackageFrom(_.get(value, "name", ""));
  };

  return (
    <Container sx={{ mt: 5, mb: 5 }} maxWidth="xl">
      {isResError && (
        <Grid item>
          <Alert severity="error">{errorResMessage}.</Alert>
        </Grid>
      )}
      <Card variant="outlined" sx={{ p: 3, borderRadius: 2 }}>
        <CardContent>
          <Grid container flexDirection={"column"} rowSpacing={2}>
            <Grid
              item
              sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
              lg={6}
              xl={6}
              md={6}
              xs={12}
              sm={12}
            >
              <Typography
                fontFamily={"Source sans pro"}
                color={useTheme().palette.grey[500]}
                variant="subtitle1"
              >
                Where is your package from?
              </Typography>
              <DropdownComponent
                isError={locationError}
                error={"Location is required"}
                onChange={onChangeLocation}
                dropdownList={list}
                disabled={false}
                label="Please Select Location"
              />
            </Grid>
            <Grid
              item
              sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
              lg={6}
              xl={6}
              md={6}
              xs={12}
              sm={12}
            >
              <Typography
                variant="subtitle1"
                fontFamily={"Source sans pro"}
                color={useTheme().palette.grey[500]}
              >
                How much does the package weigh? Kilograms (kg)
              </Typography>
              <InputComponent
                isError={weightError}
                error={
                  weightVal == 0 ? "Weight is required" : "Weight is invalid"
                }
                onChange={onWeight}
                type={"number"}
                label="Weight"
              />
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                fontFamily={"Source sans pro"}
                color={useTheme().palette.grey[500]}
              >
                Fortunately, you do not have to worry about complex dimensional
                weight calculations. Presto Worldwide pricing system gives you
                guaranteed upfront pricing based only on the package's weight
                (excludes oversized* and palletized shipments).
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                fontFamily={"Source sans pro"}
                color={useTheme().palette.grey[500]}
              >
                *Oversized shipments are defined as linear dimensions (total
                Length + Width + Height) greater than 80 inches{" "}
              </Typography>
            </Grid>
            <Grid item>
              <ButtonComponent
                onClick={onGetUpdate}
                variant="contained"
                name={"Get Shipping Cost"}
              />
            </Grid>
            {isError && (
              <Grid item>
                <Alert severity="error">{errorMessage}.</Alert>
              </Grid>
            )}
          </Grid>

          {genaratePrice ? (
            <Box mt={2}>
              <Divider />
              <Stack sx={{ mt: 2 }} spacing={2}>
                <Box>
                  <Typography
                    variant="h6"
                    fontFamily={"Source Sans Pro"}
                    color={"#572682"}
                    fontWeight={600}
                  >
                    Estimated Shpping Costs
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                    color={"GrayText"}
                    fontWeight={500}
                  >
                    Shipping cost does not reflect your additional option costs.
                  </Typography>
                </Box>

                <Grid
                  container
                  justifyContent={"center"}
                  spacing={1}
                  alignItems={"center"}
                >
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"Source Sans Pro"}
                      fontWeight={600}
                      color={"GrayText"}
                    >
                      Weight
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"Source Sans Pro"}
                      fontWeight={600}
                      color={"black"}
                    >
                      {`  ${_.get(resposeData, "weight", 0)}${_.get(
                        resposeData,
                        "unit",
                        "kg"
                      )}`}
                    </Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"Source Sans Pro"}
                      fontWeight={600}
                      color={"GrayText"}
                    >
                      {/*  */}
                      Shipping Cost
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"Source Sans Pro"}
                      fontWeight={600}
                      color={"black"}
                    >
                      {` ${_.get(resposeData, "shippingCost", 0)} ${onGetCurrencySymble(
                        _.get(resposeData, "currency", "USD")
                      )} `}
                    </Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"Source Sans Pro"}
                      fontWeight={600}
                      color={"GrayText"}
                    >
                      Transit Time
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"Source Sans Pro"}
                      fontWeight={600}
                      color={"black"}
                    >{` ${_.get(resposeData, "transmitTime", "")}`}</Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"Source Sans Pro"}
                      fontWeight={600}
                      color={"GrayText"}
                    >
                      Shipping Carrer
                    </Typography>
                    <img src={_.get(resposeData, "courier_logo")} />
                  </Grid>
                </Grid>
                <Accordion sx={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography
                      variant="subtitle1"
                      color={"#572682"}
                      fontWeight={600}
                      fontFamily={"Source Sans Pro"}
                    >
                      View more options
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableComponent
                      columns={column}
                      selectableRows={"none"}
                      search={false}
                      data={data}
                    />
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Box>
          ) : null}
        </CardContent>
      </Card>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
