import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { IMAGES } from "../../../assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Grid, Stack } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { PopupComponent } from "../../atoms";
import { AlertConfirmation } from "../Views/AlertConfirmation/AlertConfirmation";
import { AuthRepository } from "../../../repository";
import { ErrorMessage } from "../Views/ErrorMessage/ErrorMessage";
import _ from "lodash";
import { UTIL_METHODS } from "../../../utils";

const publicPages = [
  { url: "/shipping-calculator", name: "Shipping Calculator" },
  { url: "/contact-us", name: "Contact Us" },
  { url: "/sign-in", name: "login" },
  { url: "/sign-up", name: "Register" },
];

const privatePages = [
  { url: "/my-suite", name: "My Suite" },
  { url: "/history/transactions", name: "Transaction History" },
  { url: "/shipping-calculator", name: "Shipping Calculator" },
  { url: "/my-account", name: "My Account" },
  { url: "/contact-us", name: "Contact Us" },
  { url: "#", name: "Sign Out" },
];

export default function Navbar({ isLogged = false }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [path, setPath] = useState("");
  const [confirmView, setConfirmView] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  let access_token = UTIL_METHODS.getToken();

  useEffect(() => {
    setPath(location?.pathname);
  }, [location]);

  const onSignOut = async () => {
    const data = await AuthRepository.onSignOut();
    if (data?.status_code == 1) {
      localStorage.clear();
      navigate("/", { state: { refresh: true } });
     
    } else {
      setIsError(true);
      setErrorMsg(_.get(data, "message") == undefined ? 'Network Error' : _.get(data, "message"));
    }
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavClick = (url) => {
    if (url == "#") {
      setConfirmView(true);
    }
  };

  const handleSignOut = () => {
    setConfirmView(false);
    onSignOut();
  };
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Stack>
        {/* <Box>
          <IconButton>
            <CancelOutlinedIcon fontSize="medium" color="#fff" />
          </IconButton>
        </Box> */}
        <List>
          {access_token != "" && access_token != null ? (
            <>
              {privatePages?.map((item, index) => (
                <ListItem
                  key={index + 1}
                  onClick={() => handleNavClick(item.url)}
                >
                  <Link to={item?.url}>
                    <ListItemButton
                      sx={{
                        borderRadius: 3,
                        ":hover": { bgcolor: "#fff2", color: "#fff" },
                      }}
                    >
                      <ListItemText
                        sx={{ fontWeight: 600, color: "#fff" }}
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </>
          ) : (
            <>
              {publicPages?.map((item, index) => (
                <ListItem key={index + 1}>
                  <Link to={item?.url} style={{textDecoration:'none'}}>
                    <ListItemButton
                      sx={{
                        borderRadius: 3,
                        ":hover": { bgcolor: "#fff2", color: "#fff" },
                      }}
                    >
                      <ListItemText
                        sx={{ fontWeight: 600, color: "#fff" }}
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </>
          )}
        </List>
      </Stack>
    </Box>
  );

  return (
    <>
      <AppBar position="relative" sx={{ pt: 3, pb: 3 }}>
        <Container maxWidth="xl">
          <Toolbar>
            {/* Mobile View  */}
            <Box
              sx={{
                display: {
                  lg: "none",
                  lg: "none",
                  md: "none",
                  sm: "block",
                  xs: "block",
                },
                // bgcolor:'yellow',
                width: "100%",
              }}
            >
              <Grid container alignItems={"center"}>
                <Grid item xs={1}>
                  <IconButton
                    sx={{ color: "#fff" }}
                    aria-label="Menu-icon"
                    onClick={toggleDrawer(true)}
                  >
                    <MenuIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Container maxWidth="xl" > */}
                  <Link to={"/"}>
                    <img
                      src={IMAGES.LOGO.PRESTO_LOGO_WW}
                      alt="logo"
                      style={{ width: 180, objectFit: "contain" }}
                    />
                  </Link>
                  {/* </Container> */}
                </Grid>
              </Grid>
            </Box>

            {/* Desktop View */}
            <Box
              sx={{
                display: {
                  lg: "block",
                  lg: "block",
                  md: "block",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <Link to={"/"}>
                <img
                  src={IMAGES.LOGO.PRESTO_LOGO_WW}
                  alt="logo"
                  style={{ width: 210, objectFit: "contain" }}
                />
              </Link>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                gap:"10px",
              }}
            >
              {access_token != "" && access_token != null ? (
                <>
                  {privatePages?.map((page, index) => (
                    <Link to={page?.url} key={index + 1} style={{textDecoration:'none'}}>
                      <Button
                        onClick={() => handleNavClick(page.url)}
                        sx={{
                          my: 2,
                          color: path == page.url ? "#c2f047" : "white",
                          display: "block",
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          fontFamily: "Source sans pro",
                          textTransform: "capitalize",
                          position: 'relative',
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            left: 0,
                            bottom: -2,
                            width: 0,
                            height: '2px',
                            backgroundColor: '#76BA00',
                            transition: 'width 0.3s ease-in-out',
                          },
                          '&:hover::after': {
                            width: '100%',
                          },
                          '&:hover': {
                            color: '#76BA00',
                          }
                        }}
                        
                      >
                        {page?.name}
                      </Button>
                    </Link>
                  ))}
                </>
              ) : (
                <>
                  {publicPages?.map((page, index) => (
                    <Link to={page?.url} key={index + 1} style={{textDecoration:'none'}}>
                      <Button
                        onClick={handleCloseNavMenu}
                        sx={{
                          my: 2,
                          color: path == page.url ? "#c2f047" : "white",
                          display: "block",
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          fontFamily: "Source sans pro",
                          textTransform: "capitalize",
                          position: 'relative',
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            left: 0,
                            bottom: -2,
                            width: 0,
                            height: '2px',
                            backgroundColor: '#76BA00',
                            transition: 'width 0.3s ease-in-out',
                          },
                          '&:hover::after': {
                            width: '100%',
                          },
                          '&:hover': {
                            color: '#76BA00',
                          }
                        }}
                        
                      >
                        {page?.name}
                      </Button>
                    </Link>
                  ))}
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            bgcolor: "#572682",
            color: "#fff",
          },
        }}
        open={open}
        onClose={toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>

      <PopupComponent
        maxWidth={"xs"}
        actionButtonName={"Yes"}
        content={
          <AlertConfirmation message={"Are you sure you want to sign out?"} />
        }
        secondaryBtnName={"No"}
        open={confirmView}
        title={"Sign Out"}
        handleClose={() => setConfirmView(false)}
        handleAction={() => handleSignOut()}
      />

      <PopupComponent
        maxWidth={"xs"}
        actionButtonName={"OK"}
        content={<ErrorMessage message={errorMsg} />}
        showSecondaryBtn={false}
        open={isError}
        title={"Something Went Wrong"}
        handleClose={() => setIsError(false)}
        handleAction={() => setIsError(false)}
      />
    </>
  );
}
