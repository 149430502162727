import React from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import _ from "lodash";
import { InputComponent, RadioButtonComponent } from "../../../atoms";
import { HostedCheckout } from "../../../modules";
import axios from 'axios';

export const PaymentOptions = ({
  data = [],
  radioButton,
  handleChangeRadio = () => { },
  amount="",
  description="",
  packageId="",
  serviceName,
  shipmentData = {},
  handlePopupClose = () => { },
}) => {

  function errorCallback(error) {
    // console.log(JSON.stringify(error));
  }
  function cancelCallback() {
    // console.log('Payment cancelled');
  }

  return (
    <Card sx={{ boxShadow: 'none' }}>
      <CardContent>
        <Grid container alignItems={"center"}>
          <Grid item xs={12}>
            {/* {_.map(data, (item, index) => {
              return (
                <Box key={index + 1}>
                  <RadioButtonComponent
                    name={"payment"}
                    selectedValue={radioButton}
                    value={_.get(item, "id")}
                    label={_.get(item, "slug")}
                    image={_.get(item, "image")}
                    handleChange={() => handleChangeRadio(_.get(item, "id"))}
                  />
                </Box>
              );
            })} */}
            <HostedCheckout closeWindow={handlePopupClose} amount={amount} serviceName={serviceName} description={description} packageId={packageId} shipmentData={shipmentData} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
