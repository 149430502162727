import React, { useEffect, useState, useRef } from "react";
import { Box, Container, Grid, Typography, CardMedia } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { IMAGES } from "../../assets";
import {
  ButtonComponent
} from "../../components/atoms";
import { DashBoardRepository } from "../../repository";

export default function ReturnPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const resultIndicator = queryParams.get("resultIndicator") || "Unknown";
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const hasFetchedData = useRef(false);

  useEffect(() => {

      getOrderDetails();
     
  }, []);

  const getOrderDetails = async () => {
    try {
      setIsLoading(true);
      let dataset = { resultIndicator: resultIndicator };
      const response = await DashBoardRepository.getPaymentResponse(dataset);
   
      setMessage(response.message)
      setIsLoading(false);
      setResult(response.data.result == 'SUCCESS' && response.status_code == 0 ? 'WARNING' : response.data.result);
     
    } catch (error) {

    } finally {
      setIsLoading(false);
    }

  }

  const buttonNavigation = () => {
    result == 'SUCCESS' ? navigate("/history/transactions") :   navigate("/my-suite")
  };

  return (
    <Container sx={{ mt: 5, mb: 5 }} maxWidth="xl">

      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, alignItems: "center" }}>
        <Typography
          variant="h6"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          color={"#572682"}
          align="center"
        >
          {message}
          {/* Payment Successful! Thank you for your purchase. */}
        </Typography>
        {result == 'SUCCESS' ?
          <CardMedia
            component="img"
            alt="SUCCESS"
            image={IMAGES.LOGO.SUCCESS_ICON}
            sx={{ backgroundSize: "contain", width: 170 }}
          />
          : result == 'FAILURE' ?
            <CardMedia
              component="img"
              alt="FAILURE"
              image={IMAGES.LOGO.CROSS_ICON}
              sx={{ backgroundSize: "contain", width: 170 }}
            />
            : result == 'PENDING' || result == 'UNKNOWN' || result == 'WARNING' ? <CardMedia
              component="img"
              alt="WARNING"
              image={IMAGES.LOGO.INFO_ICON}
              sx={{ backgroundSize: "contain", width: 170 }}
            /> : ''}

        <ButtonComponent
          variant="contained"
          name={isLoading ? "Processing..." : result == 'SUCCESS' ? 'Check your Transaction':'Go to My Suite'}
          onClick={buttonNavigation}
          disabled={isLoading}
        />
      </Box>
    </Container>
  );
}
