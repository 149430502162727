import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Container, Grid } from "@mui/material";
import {
  ForgotPasswordForm,
  ResetPasswordForm,
  SignInForm,
  VerifyOTPForm,
} from "../../components/organisms";
import { IMAGES } from "../../assets";
import { theme } from "../../style/Theme/Theme";
import { AUTH_SCREEN_STATES } from "../../utils/Credentials";

export default function Authentication() {
  const [state, setState] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {

    const urlState = searchParams.get("state");
    if (urlState) {
      setState(AUTH_SCREEN_STATES.FORGOTPASSWORD);
      setSearchParams({});
    }else{
      setState(AUTH_SCREEN_STATES.LOGIN);
    }
  
  }, []);

  const changeView = (value) => {
    setState(value);
  };
  return (
    <Container sx={{ pt: 12, pb: 12 }} maxWidth="lg">
      <Card
        variant="outlined"
        sx={{
          borderRadius: 3,
          minHeight: 300,
          display: "flex",
          alignItems: "center",
          borderStyle: {
            xl: "solid",
            lg: "solid",
            md: "solid",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Grid container className="sign-in-container">
          <Grid
            item
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              paddingInline: 2,
              // alignItems:'center',
              // maxHeight:"inherit",
              // minHeight:300 ,
            }}
            bgcolor={theme.palette.primary.main}
            xl={6}
            lg={6}
            md={6}
            sm={6}
          >
            <div className="img-container">
              <img
                src={IMAGES.LOGO.BANNER_01}
                alt="img1"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} p={2}>
            <div className="auth-right-container">
              {AUTH_SCREEN_STATES.LOGIN == state ? (
                <SignInForm
                  onClickForgotPw={() =>
                    changeView(AUTH_SCREEN_STATES.FORGOTPASSWORD)
                  }
                />
              ) : AUTH_SCREEN_STATES.FORGOTPASSWORD == state ? (
                <ForgotPasswordForm viewChange={changeView} />
              ) : AUTH_SCREEN_STATES.VERIFY == state ? (
                <VerifyOTPForm viewChange={changeView} />
              ) : (
                <ResetPasswordForm viewChange={changeView} />
              )}
            </div>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}
