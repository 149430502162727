import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Typography, 
  CircularProgress,
  Backdrop } from "@mui/material";
export const FaqPage = () => {
  const [open, setOpen] = React.useState(true);


  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container  sx={{ mt: 2, mb: 2 }} maxWidth="xl">
      <Stack>
        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
            Registration Information
          </Typography>
        </Box>
        <Box>
          <ol
            type="a"
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              color: "GrayText",
            }}
          >
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
              How can I register for this service?
            </li>
            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
              sx={{ textAlign: "justify" }}
            >
              If you already have a Presto Worldwide account, then you can simply log in
               using your existing account; or you can register on Presto 
               Worldwide by providing the relevant details.
            </Typography>
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
              Can I start using Presto Worldwide services immediately after I
              sign up?
            </li>
            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
            >
              Yes. Only you receive your account confirmation, you are ready to
              start shopping!
            </Typography>
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
              I understand you will give me a US address. Is it a P.O box
              address?
            </li>
            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
              sx={{ textAlign: "justify" }}
            >
              No. Presto Worldwide provides you with a complete street address
               and unique suite number, not a P.O. box address. All US
                shipping companies will be able to deliver to your Presto Worldwide address.
            </Typography>
          </ol>
        </Box>
        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
            Account Information
          </Typography>
        </Box>
        <Box>
          <ol
            type="a"
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              color: "GrayText",
            }}
          >
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
              How do I use my Presto Worldwide address?
            </li>
            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
            >
              Enter your Presto Worldwide address as the "Shipping Address" 
              as shown below, whenever you buy/checkout from US online stores.
            </Typography>

            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"darkblue"}
              fontWeight={600}
            >
              Name: Your Name <br />
              Address (Line1): xxxx Express Lane, <br />
              Address(Line2): Suite xxx xxx (Enter your suite number here),
              <br /> City: Sarasota <br /> State: Florida or FL Zip Code/postal
              Code: 34249
            </Typography>

            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
            >
              You can find your presto worldwide address when you sign into your
              account, on the upper right hand corner of your screen.
            </Typography>
          </ol>
        </Box>

        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
            Declared values and Receipts
          </Typography>
        </Box>
        <Box>
        <ol
            type="a"
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              color: "GrayText",
            }}
          >
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
            Why do you need the purchase values of my items, and how do I confirm them?
            </li>
           
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            Customers require the purchase value of all imported products. If
            your package arrives without an invoice to our warehouse, Presto
            Worldwide will require the customer to update the details by uploading
            your merchant Invoice/Receipt into the system, under the action
            required tab.{" "}
          </Typography>
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
            What is a merchant invoice?
            </li>
            <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
           A Merchant Invoice (MI), or payment receipt, is a document that shows the price you paid for merchandise you have purchased. The document may also contain other information, including the name and address of the seller(s).
           Many merchants automatically include a merchant invoice with your package, and some may email you an electronic merchant invoice. 
           Please keep a record of your merchant invoices so you can provide them to Presto Worldwide or the customs office if required for export or import purposes.
          </Typography>
          <li style={{ fontWeight: 600, marginTop:'10px' }}>
            Gift Items
          </li>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
          If you indicate your item is a gift, the seller may include a merchant invoice that 
          does not show the price you paid for your merchandise. If your package arrives with 
          a gift receipt rather than a complete merchant invoice, you will be asked to provide 
          additional information before Presto Worldwide can export your purchases.
          </Typography>
          <li style={{ fontWeight: 600, marginTop:'10px' }}>
          How does a merchant invoice help me get my purchases more quickly?
          </li>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
          To comply with US export regulations, Presto Worldwide must have the actual purchase 
          prices of items that are to be exported. In many cases, we must also have the name and address of the seller.
            <br/>
          When Presto Worldwide receives a package with a merchant invoice that includes prices, 
          we will enter the values and seller’s name and address on the Proforma Invoice for you. 
          In most cases, we will not need any additional information from you, so the merchant invoice 
          can help you receive your purchases more quickly. <br/>
          If your package arrives without a merchant invoice, or if the invoice does not show the price 
          you paid for merchandise, we cannot export your packages until we receive this information. 
          Any packages requiring values or invoices can be found in the Action Required tab in My Suite.
          </Typography>
          <li style={{ fontWeight: 600, marginTop:'10px' }}>
          Why do I need to enter my merchandise values?
          </li>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
          Regulations under Customs and the US Census Bureau require you to accurately represent 
          the price you paid for your products. We create your Proforma Invoice for Customs by 
          entering the information provided on the merchant invoice/receipt that usually arrives with your 
          package. The Proforma Invoice lists the value, quantity and description of the items. <br/>

          If your package does not include a merchant invoice, or if it does not show the value of 
          the merchandise, we will need you to confirm the values before we can ship your package.
          </Typography>
          </ol>
        </Box>
        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
           Shipping Rates
          </Typography>
        </Box>
        <Box>
        <ol
            type="a"
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              color: "GrayText",
            }}
          >
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
            What is Dimensional Weight?
            </li>
            <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
        All international carriers base their shipping rates on the greater value between the Actual 
        Weight and Dimensional Weight. <br/>

        <b>Actual Weight</b> is the weight of the package when put on a scale. Dimensional weight is based 
        on the size of the package. Large items that have a low Actual Weight relative to their size-- 
        like pillows and lamp shades, will result in a larger Dimensional Weight. Some people refer to 
        this as “volumetric weight.” <br/><br/>

        <b>Dimensional Weight</b> is calculated by multiplying the dimensions of the item. Length multiplied 
        by Width multiplied by Height, then dividing that total by the "DIM Factor." The industry standard 
        DIM Factor is 139. So, the formula is:<br/>

        <i>Dimensional Weight (chargeable kilograms) = Length x Width x Height (inches) / 139</i><br/>

        Fortunately, you do not have to worry about complex dimensional weight calculations. 
        The Presto Worldwide pricing system gives you guaranteed upfront pricing based only on the package's 
        weight (excludes oversized* and palletized shipments). <br/>

        *Oversized shipments are defined as linear dimensions (total Length + Width + Height) greater than 80 inches (203 cm). 
        Items requiring some types of special packaging (e.g. crates) are not eligible for the above pricing.

          </Typography>
            </ol>
        </Box>
        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
           Package Receiving & Storage
          </Typography>
        </Box>
        <Box>
        <ol
            type="a"
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              color: "GrayText",
            }}
          >
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
            Will I get a notification informing me that my order(s) from the US retailer(s) has arrived to my suite?
            </li>
            <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            You will receive an email from Presto Worldwide informing you of the arrival of your order(s) to the suite. 
            You can then request for your items to be shipped to Sri Lanka or, if you are expecting more deliveries, you can store.
            </Typography>
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
            Will you open my packages?
            </li>
            <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            Yes. Presto Worldwide opens every package when it arrives at our facility. Our trained agents carefully 
            review your purchases to ensure they were not broken during shipping and can be legally exported from the USA.<br/>

            If the merchant included an invoice, we will compare the contents of the package to the purchases shown 
            on the invoice to ensure you received the correct number of items. If the invoice shows the price you paid, 
            we will also enter these values for you. <br/>

            If you receive an item that is not in the manufacturer's original packaging, or is not from a trusted merchant, 
            we will open the product packaging to inspect the item. This allows Presto Worldwide to reduce fraud, and saves 
            you shipping costs by verifying the contents of your package and can be legally exported from the USA.
            </Typography>
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
            Will I be charged for storage?
            </li>
            <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
           Packages stored past the free storage period (30 days) will incur storage fees at the rates shown below. Storage fees continue to accrue until your package is shipped, discarded or returned to the sender. 
           You will see storage charges itemized on your invoice when the package is shipped or discarded.
        
           <table className="table" style={{border:'1px'}}>
              <tbody>
                <tr><td scope="col"></td><td scope="col"><b>Pricing</b></td></tr>
                <tr><td>Day 1 to Day 30 &nbsp; &nbsp;: </td><td>Free</td></tr>
                <tr><td>Day 31 to Day 60 : </td><td>USD 1.00/day</td></tr>
                <tr><td>Day 61 to Day 90 : </td><td>USD 2.00/day</td></tr>
              </tbody>
            </table>
            <br/>
            </Typography>

            <li style={{ fontWeight: 600, marginTop:'10px' }}>
            Why do I sometimes receive multiple packages even though I only placed one order with a merchant?
            </li>
            <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
           Merchants often ship parts of the same order from different warehouses, or at different times. 
           This means you may receive two or more packages, even though you only placed one order.
            </Typography>
            </ol>
            </Box>
            <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
           Payments & Custom Duties
          </Typography>
        </Box>
        <Box>
        <ol
            type="a"
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              color: "GrayText",
            }}
          >
            <li style={{ fontWeight: 600, marginTop:'10px' }}>
            Can I pay for purchases using my Debit Card?
            </li>
            <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >If your card is accepted by the seller, you can use your Debit card to make purchase(s). 
          This is generally mentioned on the seller’s website.</Typography>
          <li style={{ fontWeight: 600, marginTop:'10px' }}>
          How do I know how much duty/tax I have to pay?
            </li>
            <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >Once your package has been cleared, the courier company will call to inform you the exact 
          amount of the duty/tax to be paid for your order. 
          You can then pay this amount on delivery to the courier company.</Typography>
          </ol>
          </Box>
      </Stack>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
