import React, { Fragment, useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import _ from "lodash";

export const ShipmentExpandable = ({
  shipmentData={},
  to = "",
  carrier = "",
  weight = 0,
}) => {
    const [itemList, setItemList] = useState([]);
    useEffect(() => {
      // console.log('shipmentData:',shipmentData);
      // console.log('shipmentData:',_.get(shipmentData, "[6].shipmentItems",[]));
      // const packageItemDetails = _.get(shipmentData, "[3].shipmentItems",[]);
      // setItemList(packageItemDetails);
    }, [shipmentData]);
  return (
    <Stack spacing={0}>
      <Typography
        variant="subtitle1"
        color={"#572682"}
        fontFamily={"Source Sans Pro"}
      >
        Requested Options
      </Typography>
      <Grid container spacing={2} justifyContent={"space-around"}>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={"GrayText"}
            fontFamily={"Source Sans Pro"}
          >
            Shipping to: {to}{" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={"GrayText"}
            fontFamily={"Source Sans Pro"}
          >
            Shipping Carrier: {carrier}{" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            color={"GrayText"}
            fontFamily={"Source Sans Pro"}
          >
            Estimated Weight: {weight}{" "}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};
