import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Grid, Stack } from "@mui/material";
import {
  InputComponent,
  ButtonComponent,
  TableComponent,
} from "../../components/atoms";
import { TransactionExpandable } from "../../components/organisms";
import { useLocation, useNavigate } from "react-router-dom";
import { ShipmentRepository, TransactionRepository } from "../../repository";
import _ from "lodash";
import { getText, onGetCurrencySymble, setText } from "../../utils/Credentials";
import moment from "moment";
import { baseURL } from "../../repository/Repository";
import { IMAGES } from "../../assets";

function CustomTab(props) {
  const { label, customContent, ...other } = props;

  return (
    <Tab
      {...other}
      label={
        <div>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
              <Typography
                textTransform={"capitalize"}
                fontWeight={600}
                fontFamily={"Source sans pro"}
              >
                {label}
              </Typography>
            </Grid>
            <Grid item>{customContent}</Grid>
          </Grid>
        </div>
      }
    />
  );
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HistoryPage() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [days, setDays] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dayIndex, setDayIndex] = useState(0);
  const [transactionsData, setTransactionsData] = useState([]);
  const [queueData, setQueueData] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (location?.state?.tab == "queue") {
      setValue(1);
    }
  }, []);

  const onHistoryGet = async () => {
    handleOpen();
    const response = await TransactionRepository.onGetTransactions({
      days,
      invoiceId,
    });
    if (response) {
      if (response?.status_code == 1) {
        const list = _.map(_.get(response, "data", []), (item, index) => {
          const date = moment(new Date(item?.created_at)).format("MMM DD YYYY");
          const chargers = item?.amount+' '+ onGetCurrencySymble("USD") ;
          return { ...item, date, chargers };
        });
        setTransactionsData(list);
        //       onLoadDataAgain(response)
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, "message", ""));
      }
      handleClose();
    } else {
      setResError(true);
      setErrorResMessage("Please, Select the invoice");
      handleClose();
    }
  };

  const getQueueList = async () => {

    const response = await ShipmentRepository.getShipmentQueue();
    if (response) {
      if (response?.status_code == 1) {
        const list = _.map(_.get(response, "data", []), (item) => {
          const date = moment(new Date(item?.shipmentData.shipmentCreateDateTime)).format("MMM DD YYYY");
          const weight = item?.shipmentData?.shipmentWeight ? `${item.shipmentData.shipmentWeight} ${item.shipmentData.weightUnit || ""}` : "";
          const packageCount = item?.packages?.length || 0;
          const packageSeller = item?.packageRetailers.join(", ");
          const shipmentId = item?.shipmentData?.shipmentId;
          return { ...item, date, weight, packageCount, packageSeller,shipmentId };
        });
        console.log('list:',list);
        setQueueData(list);
      } else {
       
      }

    } else {

    }
  }

  const QueueColumn = [
    {
      name: "packageSeller",
      label: "From",
    },
    {
      name: "shipmentId",
      label: "Shippping Request ID",
      options: {
        setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
      },
    },
    {
      name: "date",
      label: "Request Date",
      options: {
        setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
      },
    },
    {
      name: "weight",
      label: "Weight",
      options: {
        setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
      },
    },
    {
      name: "packageCount",
      label: "Package Count",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex; 
          const rowObject = queueData[rowIndex];
          return (<>
          <Box display="flex" alignItems="center" gap={1}>
            <span><b>{value}</b></span>
            <span>{rowObject?.shipmentData.packageIds}</span>
          </Box>
          </>
          )
      },
      },
    },
    {
      name: "shipmentStatus",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex; 
          const rowObject = queueData[rowIndex];
          return (<>
          <Box display="flex" alignItems="center" gap={1}>
            <span>{rowObject?.shipmentData.shipmentStatus}</span>
          </Box>
          </>
          )
      },
      },
    },
    {
      name: "shipmentData",
      label: "shipmentData",
      options: {
        display: false,
      },
    },
  ];
  const [queueCount, setQueueCount] = React.useState(0);
  

  const transactionColumn = [
    {
      name: "state",
      label: "State",
      options: {
        display: false,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
      },
    },
    {
      name: "payment",
      label: "Type",
      options: {
        setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex; 
          const rowObject = transactionsData[rowIndex];
          return (<>
          <Box display="flex" flexDirection="column" gap={1}>
            <span>{value}</span>
            {rowObject.payment == 'shipping' && rowObject.request_details != null ?  <ButtonComponent 
            onClick={() => {
              const trackingNumber = rowObject.request_details?.tracking_no ?? "";
              const url = `https://www.fedex.com/wtrk/track/?trknbr=${encodeURIComponent(trackingNumber)}`;
              window.open(url, "_blank");
            //  navigate("/order-tracking", {
            //   state: { trackingNumber: rowObject.request_details?.tracking_no ?? "" },
            // });
              }}
               size="small" width={25} variant="contained" name={"Track Now"} />: 
              rowObject.payment == 'shipping'?<span style={{color:'lightgrey'}}>Tracking is not available yet</span>:''}
          </Box>
          </>
          )
      },
      },
    },
    {
      name: "chargers",
      label: "Charges",
      options: {
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
      },
    },
    {
      name: "invoice_path",
      label: "Invoice",
      options: {
        setCellProps: () => ({
          style: {
            textAlign: "center", // Center-align the cell content
          },
        }),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex; 
          const rowObject = transactionsData[rowIndex];
          return (<>
          <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
            <span>{rowObject.invoice_number}</span>
          <a
            href={`${baseURL}${value}`}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <img
              src={`${IMAGES.HISTORY.PDF_ICON}`}
              alt="Download Invoice"
              style={{ width: 30, height: 30, cursor: 'pointer' }}
            />
          </a>
          </Box>
          </>
          )
      },
      },
    },
  ];

  const handleChange = (event, newValue) => {
    if (newValue == 0) {
      onHistoryGet();
    } else {
    }
    setValue(newValue);
  };

  const onChangeInvoicement = (e) => {
    const text = getText(e);
    setInvoiceId(text);
  };

  const onChangeDate = (value) => {
    setDays(value);
    setDayIndex(value == 30 ? 1 : value == 60 ? 2 : value == 90 ? 3 : 4);
  };

  const onGetAll = () => {
    setInvoiceId("");
    setDays("");
    setDayIndex(0);
  };

  useEffect(() => {
    onHistoryGet();
    getQueueList();
  }, [days]);

  return (
    
    <Container sx={{ mt: 5, mb: 5}} maxWidth="xl">
      <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {/* <Tab
            sx={{ textTransform: "capitalize", fontWeight: 600 }}
            label="Transactions"
            {...a11yProps(0)}
            onClick={() => { navigate("/history/transactions") }}
          /> */}

          <CustomTab
            label="Transactions"
            onClick={() => {
              navigate("/history/transactions");
            }}
            customContent={
              <Box
                sx={{
                  bgcolor: "#572682",
                  paddingInline: 1,
                  borderRadius: 50,
                  // borderStyle: viewAll > 0 ? "none" : "solid",
                  // borderColor: viewAll > 0 ? "none" : "GrayText",
                  borderWidth: 1,
                }}
              >
                <Typography fontWeight={600} color={"#fff"} variant="caption">
                  {transactionsData.length < 10 ? `0${transactionsData.length}` : transactionsData.length}
                </Typography>
              </Box>
            }
          />

          <CustomTab
            label={"Queue"}
            onClick={() => navigate("/history/queue")}
            customContent={
              <Box
                sx={{
                  bgcolor: "#572682",
                  paddingInline: 1,
                  borderRadius: 50,
                  // borderStyle: viewAll > 0 ? "none" : "solid",
                  // borderColor: viewAll > 0 ? "none" : "GrayText",
                  borderWidth: 1,
                }}
              >
                <Typography fontWeight={600} color={"#fff"} variant="caption">
                  {queueData.length < 10 ? `0${queueData.length}` : queueData.length}
                </Typography>
              </Box>
            }
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {/* <Container> */}
          <Stack spacing={4}>
            <Grid container rowGap={1}>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <Grid container>
                  <Grid item>
                    <Button
                      sx={{textTransform:'capitalize', padding:'6px 16px'}}
                      variant={dayIndex == 0 ? "contained" : "text"}
                      onClick={() => {
                        onGetAll();
                      }}
                    >
                      Show List
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{textTransform:'capitalize', padding:'6px 16px'}}
                      variant={dayIndex == 1 ? "contained" : "text"}
                      onClick={() => {
                        onChangeDate("30");
                      }}
                    >
                      {" "}
                      30 days
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{textTransform:'capitalize', padding:'6px 16px'}}
                      variant={dayIndex == 2 ? "contained" : "text"}
                      onClick={() => {
                        onChangeDate("60");
                      }}
                    >
                      60 days
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{textTransform:'capitalize', padding:'6px 16px'}}
                      variant={dayIndex == 3 ? "contained" : "text"}
                      onClick={() => {
                        onChangeDate("90");
                      }}
                    >
                      90 days
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{textTransform:'capitalize', padding:'6px 16px'}}
                      variant={dayIndex == 4 ? "contained" : "text"}
                      onClick={() => {
                        onChangeDate("all");
                      }}
                    >
                      all
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={8}
                xs={12}
                sx={{
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                    xs: "flex",
                  },
                  justifyContent: "flex-end",
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  spacing={1}
                  paddingInlineEnd={2}
                >
                  <Grid item xs={8}>
                    <InputComponent
                      value={invoiceId}
                      onChange={onChangeInvoicement}
                      label="Invoice Number"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ButtonComponent
                      onClick={onHistoryGet}
                      variant="contained"
                      name="Search"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid
                item
                xl={2}
                lg={2}
                md={6}
                sm={4}
                xs={12}
                sx={{
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                    xs: "flex",
                  },
                  justifyContent: "flex-end",
                }}
              >
                <ButtonComponent variant="contained" name="Export Packages" />
              </Grid> */}
            </Grid>

            <TableComponent
              // expandableRows={true}
              columns={transactionColumn}
              selectableRows="none"
              data={transactionsData}
              title="Transactions"
              filterType=""
            />
          </Stack>
        {/* </Container> */}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TableComponent
          columns={QueueColumn}
          data={queueData}
          selectableRows="none"
          title="Queue"
          // expandableRows={true}
        />
      </CustomTabPanel>
    </Container>
  );
}
