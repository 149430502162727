import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components/organisms";
import { Outlet, useLocation } from "react-router-dom";
import { Box, Container, Divider, Typography } from "@mui/material";

export default function HeadingScreen({isAuth=false}) {
  const [currentPage, _] = useState(window.location.pathname);
  const [headingName, setHeadingName] = useState("");
  const location = useLocation();

  useEffect(() => {
    switch (location?.pathname) {
      case "/shipping-calculator":
        setHeadingName("Shipping Calculator");
        break;
      case "/my-account":
        setHeadingName("My Account");
        break;
      case "/transaction":
        setHeadingName("History");
        break;
      case "/contact-us":
        setHeadingName("Contact Us");
        break;
      case "/about-us":
        setHeadingName("About Us");
        break;
      case "/faq":
        setHeadingName("FAQ");
        break;
      case "/terms-and-conditions":
        setHeadingName("Terms & Conditions");
        break;
      case "/privacy-policy":
        setHeadingName("Privacy Policy");
        break;
      case "/history/transactions":
        setHeadingName("Transaction History");
        break;
      case "/history/queue":
        setHeadingName("Queue");
        break;
      case "/order-details":
        setHeadingName("Order Details");
        break;
      case "/how-it-works":
        setHeadingName("How it works");
        break;
      case "/order-tracking":
        setHeadingName("Track Order");
        break;
      case "/prohibited-items":
        setHeadingName("Prohibited Items");
        break;
      default:
        setHeadingName("Page not Found 404 :(");
    }
  }, [location]);

  return (
    <>
      <Navbar isLogged={isAuth} />
      <Box sx={{ pt: 2, pb: 2, bgcolor: "#f8f8f8" }}>
        <Container maxWidth="xl">
          <Typography
            variant="h5"
            fontFamily={"Source Sans Pro"}
            color={"#44425a"}
            fontWeight={600}
            textTransform={"capitalize"}
          >
            {headingName}
          </Typography>
        </Container>
      </Box>
      <Divider />
      <Outlet />
      <Box position={"relative"} sx={{ top: "auto", bottom: 0 }}>
        <Footer />
      </Box>
    </>
  );
}
