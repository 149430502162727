import PRESTO_LOGO_WW from "./logos/presto-world-wide.svg";
import PRESTO_LOGO_MINI from "./logos/presto-mini.svg";
import BANNER_01 from "./logos/banner-1.svg";
import FOOTER_LOGO from "./Presto Assets/Presto Assets/footer-logo.svg";
import HOME_SIGNUP from "./Presto Assets/Presto Assets/sign-up-service.svg";
import HOME_TAX_FREE_ADDRESS from "./Presto Assets/Presto Assets/tax-free-service.svg";
import HOME_FREE_PACKAGE from "./Presto Assets/Presto Assets/free-package-service.svg";
import HOME_INTERNATIONAL_BRANDS from "./Presto Assets/Presto Assets/International-brands-service.svg";
import HOME_SHIPPING_RATES from "./Presto Assets/Presto Assets/shipping-rates-service.svg";
import HOME_STORAGE from "./Presto Assets/Presto Assets/free-storage-service.svg";
import HOW_IT_WORKS_BANNER from "./Presto Assets/Presto Assets/how-its-work-banner.jpg";
import US_FLAG from "./Presto Assets/Presto Assets/us.svg";
import EBAY_LOGO from "./Presto Assets/Presto Assets/20200214125712.png";
import AMERZON_LOGO from "./Presto Assets/Presto Assets/20200214125755.png";
import WALMART_LOGO from "./Presto Assets/Presto Assets/20200214125922.png";
import DISNEY_LOGO from "./Presto Assets/Presto Assets/20200214130037.png";
import BARH_N_BODY_WORKS_LOGO from "./Presto Assets/Presto Assets/20200214130159.png";
import CARTERS_LOGO from "./Presto Assets/Presto Assets/20200214130256.png";
import SUCCESS_ICON  from "./Presto Assets/Presto Assets/Success.png";
import CROSS_ICON from "./Presto Assets/Presto Assets/Cross.png";
import INFO_ICON from "./Presto Assets/Presto Assets/Info.png";
import PDF_ICON from "./Presto Assets/Presto Assets/pdf.png";
import PAYMENT_SOLUTIONS from "./logos/payment-solutions.jpg";

export const IMAGES = {
  LOGO: {
    PRESTO_LOGO_WW,
    PRESTO_LOGO_MINI,
    BANNER_01,
    FOOTER_LOGO,
    CARTERS_LOGO,
    EBAY_LOGO,
    WALMART_LOGO,
    BARH_N_BODY_WORKS_LOGO,
    DISNEY_LOGO,
    AMERZON_LOGO,
    INFO_ICON,
    CROSS_ICON,
    SUCCESS_ICON,
    PAYMENT_SOLUTIONS
  },
  HOME: {
    HOME_SIGNUP,
    HOME_TAX_FREE_ADDRESS,
    HOME_FREE_PACKAGE,
    HOME_INTERNATIONAL_BRANDS,
    HOME_SHIPPING_RATES,
    HOME_STORAGE,
  },
  MYSUIT: {
    HOW_IT_WORKS_BANNER,
    US_FLAG,
  },
  HISTORY:{
    PDF_ICON
  }
};
