import React, { useEffect, useState } from "react";
import { Card, Container, Divider, Grid, Typography, useTheme, Backdrop, CircularProgress, Alert, Snackbar } from "@mui/material";
import { ButtonComponent, DropzoneComponent, InputComponent } from "../../components/atoms";
import { EMAIL_REGEX, PHONE_REGEX, getText, setText } from "../../utils/Credentials";
import { CommonRepositoty } from "../../repository";
import _ from "lodash";

const vertical = 'top', horizontal = 'center';



export default function ContactUsPage() {
  const [fName, setFName] = useState('');
  const [isFNameValid, setIsFNameValid] = useState(false);
  const [fNameError, setFNameError] = useState(false);

  const [lName, setLName] = useState('');
  const [isLNameValid, setIsLNameValid] = useState(false);
  const [lNameError, setLNameError] = useState(false);

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [errorPhone, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('Mobile number is required');

  const [suite, setSuite] = useState('');
  const [isSuiteValid, setIsSuiteValid] = useState(false);
  const [suiteError, setSuiteError] = useState(false);

  const [message, setMessage] = useState('');
  const [isMessageValid, setIsMessageValid] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState('');
  const [successResMessage, setSuccessResMessage] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [isOpenSnackBar, setOpenSnackBar] = useState(false)


  const [img, setImg] = useState(null);

  useEffect(() => {
    const data = localStorage.getItem('user');
    const userData = JSON.parse(data);
    if(_.get(userData, 'email', '') != ""){
      onEmailChange(setText(_.get(userData, 'email', '')));
    }

    onChangeFirstName(setText(_.get(userData, 'first_name', '')))
    onChangeLastName(setText(_.get(userData, 'last_name', '')))
    
    if(_.get(userData, 'mobile', '') != ""){
      onChangePhone(setText(_.get(userData, 'mobile', '').startsWith('+94') ? _.get(userData, 'mobile', '').slice(3) : _.get(userData, 'mobile', '').startsWith('94') ? _.get(userData, 'mobile', '').slice(2) : _.get(userData, 'mobile', '')))
    }
  
    onChangeSuite(setText(_.get(userData, 'suite_no', '')))

  }, []);


  const onEmailChange = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setEmail(text);
    setIsEmailValid(isValid);
    setEmailError(!isValid? true: false);
  };

  const onChangeFirstName = (e) => {
    const text = getText(e)
    setFName(text);
    setFNameError(false);
    setIsFNameValid(text?.length > 0)
  }

  const onChangeLastName = (e) => {
    const text = getText(e)
    setLName(text);
    setLNameError(false);
    setIsLNameValid(text?.length > 0)
  }


  const onChangePhone = (e) => {
    const text = getText(e);
    const reg = PHONE_REGEX;
    let isValid = false;
    if (text.length > 3) {
        isValid = reg.test(text);
    }
    setPhone(text);
    setPhoneError(!isValid);
    setIsPhoneValid(isValid);

    if(isValid == false){
      setPhoneErrorMessage('Mobile number is invalid');
    }else{
      setPhoneErrorMessage('');
    }
  }

  const onChangeSuite = (e) => {
    const text = getText(e)
    setSuite(text);
    setSuiteError(false);
    setIsSuiteValid(text?.length > 0)
  }


  const onChangeMessage = (e) => {
    const text = getText(e)
    setMessage(text);
    setMessageError(false);
    setIsMessageValid(text?.length > 0)
  }

  const onClean = () => {
    let text = setText('');
    onChangeFirstName(text);
    onChangeLastName(text);
    onChangeMessage(text);
    onChangePhone(text);
    onChangeSuite(text);
    onEmailChange(text);

    text =''
    setMessage(text);
    setSuite(text);
    setPhone(text);
    setLName(text);
    setFName(text);
    setEmail(text);
    setMessage(text);
    setImg(null)
  }


  const onSubmit = async () => {
    setResError(false)
    if (isFNameValid && isLNameValid && isEmailValid && isPhoneValid && isSuiteValid && isMessageValid && isMessageValid) {
      handleOpen()

      const fd = new FormData();
      fd.append('first_name', fName)
      fd.append('last_name', lName)
      fd.append('email', email)
      fd.append('phone', phone)
      fd.append('message', message)
      fd.append('suite', suite);
      if (img?.name) {
        fd.append('attachment', img);
      }
      const response = await CommonRepositoty.onContactUs(fd);
      if (response) {
        if (response?.status_code == 1) {
          setSuccessResMessage(_.get(response, 'message', ''))
          setOpenSnackBar(true)
          onClean()
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, 'message', ''));
        }
        handleClose()
      }
    } else {
      if (!isLNameValid) {
        setLNameError(true)
      }
      if (!isFNameValid) {
        setFNameError(true)
      }

      if (!isEmailValid) {
        setEmailError(true)
      }
      if (!isPhoneValid) {
        setPhoneError(true)
      }
      if (!isSuiteValid) {
        setSuiteError(true)
      }
      if (!isMessageValid) {
        setMessageError(true)
      }
    }
  }

  return (
    <Container sx={{ mt: 5, mb: 5 }} maxWidth="xl">
      <Card variant="outlined" sx={{ p: 4, borderRadius: 3 }}>
        <Grid container flexDirection={"column"} rowSpacing={2}>
          {isResError && <Grid item>
            <Alert severity="error">{errorResMessage}.</Alert>
          </Grid>}
          <Grid item >
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <InputComponent
                  value={fName}
                  isError={fNameError}
                  error={'First Name is required'}
                  onChange={onChangeFirstName}
                  label="First Name"
                  type={"text"} />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <InputComponent
                  value={lName}
                  isError={lNameError}
                  error={'Last Name is required'}
                  onChange={onChangeLastName}
                  label="Last Name"
                  type={"text"} />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <InputComponent
                  value={suite}
                  isError={suiteError}
                  error={'Suite is required'}
                  onChange={onChangeSuite}
                  label="Suite"
                  type={"text"} />
              </Grid>
            </Grid>

          </Grid>

          <Grid item >
          <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <InputComponent
                  value={email}
                  isError={emailError}
                  error={email?.length > 3 ? 'Email is not valid' : 'Email is required'}
                  onChange={onEmailChange}
                  label="Email"
                  type={"text"} />
              </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <InputComponent
                    value={phone}
                    isError={errorPhone}
                    error={phoneErrorMessage}
                    onChange={onChangePhone}
                    label='Mobile Number (7XXXXXXXX)'
                    type={"text"} />
                </Grid>
              </Grid>
            </Grid>
          <Grid item>
            <InputComponent
              value={message}
              isError={messageError}
              error={'Message is required'}
              onChange={onChangeMessage}
              multiline={true}
              label="Message"
              minRows={4}
              maxRows={10}
              type={"text"}
            />
          </Grid>

          <Grid item >
            <DropzoneComponent clean={isOpenSnackBar} onChange={(file) => { setImg(file) }} />
          </Grid>

          <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonComponent onClick={onSubmit} variant="contained" name={"Submit"} />
          </Grid>


          <Grid item>
            <Divider sx={{ borderWidth: 2 }} />
          </Grid>

          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: useTheme().palette.primary.main }}
            >
              Prefer to call?
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: useTheme().palette.primary.main }}
            >
              Get in touch with our Customer Services on
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, color: useTheme().palette.grey[600] }}
            >
               <a href="tel:+94761244584" style={{color:'#757575',textDecoration:'none'}}>+9476 124 4584</a>
             
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={5000}
        open={isOpenSnackBar}
        onClose={() => { setOpenSnackBar(false) }}
        message={successResMessage}
        key={'top' + 'center'}
      />
    </Container>
  );
}
