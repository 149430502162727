import React, { useEffect, useState } from "react";
import { DashBoardRepository } from '../../../repository';
import { PopupComponent } from '../../atoms';
import { Box, Grid, Typography } from "@mui/material";

const HostedCheckout = ({ amount, serviceName, description, packageId, shipmentData,closeWindow = () => { }}) => {

  const [popupOpen, setPopupOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const onPopupHide = () => {
    setPopupOpen(false);
    closeWindow(true);
  };

  useEffect(() => {
    const setupHostedCheckout = async () => {
      try {
        let dataset;
        if (serviceName == 'shipping') {
          dataset = shipmentData;
        } else {
          dataset = {
            amount: amount,
            serviceName: serviceName,
            description: description,
            packageId: packageId,
            urgent: 0
          };
        }

        const response = await DashBoardRepository.createPaymentSession(dataset);

        if (response.status_code == 0) {
          setResponseMessage(response.message)
          setPopupOpen(true);

        } else {

          const sessionId = response.data.session_id;
          if (!window.Checkout) {
            setResponseMessage("Hosted Checkout SDK is not loaded.")
            setPopupOpen(true);
        
            return;
          }

          window.Checkout.configure({
            session: {
              id: sessionId,
            },
            interaction: {
              locale: 'en_US',
              operation: 'PURCHASE',
              displayControl: {
                billingAddress: 'HIDE',
                customerEmail: 'HIDE',
                orderSummary: 'SHOW',
                shipping: 'HIDE'
              },
            },
          });

          window.Checkout.showPaymentPage();
        }
      } catch (error) {
        setResponseMessage("Error setting up Hosted Checkout")
        setPopupOpen(true);
      }

    };

    setupHostedCheckout();
  }, []);

  return (<>
    {/* <Grid container  justifyContent="center" alignItems="center" > */}
      {/* <Box p={1} xs={12} textAlign={"center"}> */}
        <Typography
          variant="h6"
          fontWeight={600}
          fontFamily={"Source Sans Pro"}
          style={{textAlign:'center'}}
        >
         Creating payment session...
        </Typography>
        <Typography
          subtitle2="h6"
          fontWeight={600}
          fontFamily={"Source Sans Pro"}
          style={{textAlign:'center'}}
        >
          Don't close the window
        </Typography>
      {/* </Box> */}
    {/* </Grid> */}

    <PopupComponent
      maxWidth={"sm"}
      actionButtonName={"Close"}
      content={ <Typography variant="h6" fontWeight={400} fontFamily={"Source Sans Pro"} align={'center'}>
       {responseMessage}
      </Typography>}
      showSecondaryBtn={false}
      open={popupOpen}
      title={"Payment session"}
      handleAction={onPopupHide}
      handleClose={onPopupHide}
    />
  </>);
};

export default HostedCheckout;
