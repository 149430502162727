import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import { Container, Divider, Grid, useTheme } from "@mui/material";
import { IMAGES } from "../../../assets";
import { Link } from "react-router-dom";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
// const StyledFab = styled(Fab)({
//   position: "absolute",
//   zIndex: 1,
//   top: -30,
//   left: 0,
//   right: 0,
//   margin: "0 auto",
// });

export default function Footer() {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const currentYear = new Date().getFullYear();
  return (
    <React.Fragment>
      <AppBar
        position="relative"
        color="primary"
        sx={{ top: "auto", bottom: 0 }}
      >
        {/* <Toolbar> */}
          <Container maxWidth="xl">
          <Grid
            container
            flexDirection={"column"}
            justifyContent={"space-between"}
            rowGap={2}
            mt={10}
            mb={5}
          >
            <Grid item>
              <Grid container justifyContent={"space-between"} rowSpacing={2}>
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: {
                      xl: "flex-start",
                      lg: "flex-start",
                      md: "flex-start",
                      sm: "center",
                      xs: "center",
                    },
                    rowGap: 3,
                  }}
                >
                  <Link to="/" onClick={handleScrollToTop}>
                  <img
                    src={IMAGES.LOGO.FOOTER_LOGO}
                    alt="white-logo"
                    className="footer-logo"
                    style={{objectFit:"contain", width:'100%'}}
                  />
                  </Link>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: {
                        xl: "flex-start",
                        lg: "flex-start",
                        md: "flex-start",
                        sm: "center",
                        xs: "center",
                      },
                      rowGap: 1,
                    }}
                  >
                    <Typography
                      textAlign={{
                        xl: "left",
                        lg: "left",
                        md: "left",
                        sm: "center",
                        xs: "center",
                      }}
                      color={"#fff"}
                      sx={{ fontSize: 13, fontWeight: 600 }}
                    >
                      {/* Presto Lanka (Pvt) Ltd <br/> 40, Poorwarama Road,<br/> Nugegoda, <br/>Sri
                      Lanka */}
                      Advantis Express (Pvt) Ltd, <br/>
                      46/56, IL Towers,<br/>
                      Nawam Mawatha,<br/>
                      Colombo 2,<br/>
                      Sri Lanka.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                      }}
                    >
                      <Box sx={{ width: 24, height: 24, color: "#fff" }}>
                        <PhoneInTalkOutlinedIcon />
                      </Box>
                      <Typography
                        color={"#fff"}
                        sx={{ fontSize: "1.1rem", fontWeight: 500 }}
                      >
                        <a href="tel:+94761244584" style={{color:'#fff',textDecoration:'none'}}>+9476 124 4584</a>
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                      }}
                    >
                      <Box sx={{ width: 24, height: 24, color: "#fff" }}>
                        <MailOutlineOutlinedIcon />
                      </Box>
                      <Typography
                        color={"#fff"}
                        sx={{ fontSize: "1.1rem", fontWeight: 500 }}
                      >
                        <a href="mailto:hey@prestoworldwide.com" style={{color:'#fff',textDecoration:'none'}}>hey@prestoworldwide.com</a>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={12}
                >
                  <ul className="footer-ul">
                    <li className="footer-items">
                      <Link to={"/how-it-works"} onClick={handleScrollToTop}>How It Works</Link>
                    </li>
                    <li>
                      <Link to={"/faq"} onClick={handleScrollToTop}>FAQ</Link>
                    </li>
                    <li>
                      <Link to={"/prohibited-items"} onClick={handleScrollToTop}>Prohibited Items</Link>
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={12}
                >
                  <ul className="footer-ul">
                    <li>
                      <Link to={"/shipping-calculator"} onClick={handleScrollToTop}>
                        Shipping Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to={"/about-us"} onClick={handleScrollToTop}>About Us</Link>
                    </li>
                    <li>
                      <Link to={"/contact-us"} onClick={handleScrollToTop}>Contact Us</Link>
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={12}
                >
                  <ul className="footer-ul">
                    <li>
                      <Link to={"/terms-and-conditions"} onClick={handleScrollToTop}>
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"} onClick={handleScrollToTop}>Privacy Policy</Link>
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={12}
                >
                  <ul className="footer-ul">
                    <li>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <Box sx={{ width: 20, height: 20 }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="#fff"
                              d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                            />
                          </svg>
                        </Box>
                        <a href={"https://www.instagram.com/prestoworldwide/"} target="_blank">
                          Instagram
                        </a>
                      </Box>
                    </li>
                    <li>

                    <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <Box sx={{ width: 20, height: 20 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#fff" d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg>
                        </Box>
                        <a
                        href={
                          "https://www.youtube.com/channel/UCaIrt9GmYRde62dw60MJFSw"
                        }
                        target="_blank"
                      >
                        Youtube
                      </a>
                      </Box>
                      
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </Container>
        {/* </Toolbar> */}
        <Divider sx={{ bgcolor: "#ffffff50" }} />
        <Box mt={5} mb={5}>
          <Typography
            color={useTheme().status.footerTextColor}
            sx={{ fontSize: "1.1rem", fontWeight: 500, color: "#fff" }}
            textAlign={"center"}
          >
            © {currentYear} Presto Lanka Pvt Ltd. All rights reserved.
          </Typography>
        </Box>
      </AppBar>
    </React.Fragment>
  );
}
